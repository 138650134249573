import React from "react"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Building Landing Zone in GCP"
const pageTitle = "Building Landing Zone in GCP"
const subTitle = "Google Cloud Platform"
const para =
  "Building on the current Landing Zone, the Future State Assessment and Migration Roadmap creates a shared understanding for success."
const blockquote = `"THIS APPLICATION IS A
STRATEGIC INITIATIVE FOR A
GLOBAL FINANCIAL
INTELLIGENCE INNOVATOR
IN INVESTMENT
MANAGEMENT​."`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          Build an initial version of modular and scalable cloud foundation that
          enables the organization to deploy and adopt Google Cloud services for
          their Business. This landing zone will be foundation to deploying on
          premise workload in the Google cloud environment.
        </p>
        {/* <img  src={MapImg}/> */}
      </>
    ),
  },

  {
    id: 1,
    heading: `The Approach`,
    listItems: (
      <>
        <p>
          Implementation of the core tooling and controls required for hybrid
          cloud: governance, security, identity, networking, observability, and
          pipelines.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Process:`,
    listItems: (
      <>
        <p>
          Engaged in a 6-week proof of concept with the client’s Platform
          engineering team, in an iterative model (Kanban) and demonstrate
          working model of the landing zone in Google cloud ​
        </p>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <>
        <p>
          The outcome of the engagement is multi-environment landing zoned
          deployed to Google Cloud Platform exclusively provisioned and managed
          in Terraform.
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query BuildingLandingZoneInGcpQuery {
    casedetailsJson(slug: { eq: "building-landing-zone-in-gcp" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
